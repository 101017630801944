import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import { Suspense, lazy } from "react";
import {AnimatePresence} from "framer-motion";
import Footer from "./Components/Footer";

const HazelnutLazy = lazy(() => import("./Components/HazelnutPage"));
const HomepageLazy = lazy(() => import("./Components/HomePage"));
const KybeleCarLazy = lazy(() => import("./Components/KybeleCar"));

function App() {
  return (
    <>
      <Navbar />

      <Suspense fallback={<>Yükleniyor...</>}>
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<HomepageLazy />} />
            <Route path="/hazelnut" element={<HazelnutLazy />} />
            <Route path="/kybele-car" element={<KybeleCarLazy />} />
          </Routes>
        </AnimatePresence>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
