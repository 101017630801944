import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

class Navbar extends Component {
    render() {
        return (
            <div className="navb">
                <div style={{ fontSize: "40px" }}>
                    <a href="/" alt="hp">
                        KYBELE
                    </a>
                </div>
                <div className='navbar-links' style={{ fontSize: "20px" }}>
                    <NavLink to="/" className='navbar-links' activeclassname="active">
                        HomePage
                    </NavLink>

                    <NavLink to="/hazelnut" className='navbar-links mx-3' activeclassname="active">
                        Kybele Hazelnut
                    </NavLink>

                    <NavLink to="/kybele-car" className='navbar-links' activeclassname="active">
                        Kybele Car
                    </NavLink>
                </div>



                <div className="footer-social">
                    <ul>
                        <li >
                            <a href="/" alt="">
                                <i className="fa fa-facebook" />
                            </a>
                        </li>
                        <li >
                            <a href="/" alt="" className="w-100 text-center">
                                <i className="fa fa-twitter" />
                            </a>
                        </li>

                        <li>
                            <a href="/" alt="" className="w-100 text-center">
                                <i className="fa fa-instagram" />
                            </a>

                        </li>
                        <li>
                            <a href="/" alt="" className="w-100 text-center">
                                <i className="fa fa-google-plus" />
                            </a>
                        </li>
                        <li>
                            <a href="/" alt="" className="w-100 text-center">
                                <i className="fa fa-linkedin" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default Navbar;